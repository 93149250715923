import React, { useState, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import CgSearchLoading from '@meronex/icons/cg/CgSearchLoading'
import {
  GeoAlt,
  Heart,
  Buildings,
  PlusLg,
  House,
  Search
} from 'react-bootstrap-icons'
import { useForm } from 'react-hook-form'

import {
  FormControl,
  FormActions,
  AddressWrap,
  WrapAddressInput,
  WrapperMap,
  WrapperSkeleton,
  Container,
  ButtonWrapper,
  AddressWrapper,
  AddressTagSection
} from './styles'

import {
  AddressForm as AddressFormController,
  GoogleAutocompleteInput,
  useLanguage,
  GoogleMapsMap,
  useSession,
  useOrder,
  useConfig,
  ToastType,
  useToast
} from '~components'

import {
  Alert,
  Button,
  Input,
  TextArea,
  GoogleGpsButton,
  useBusinessSelected,
  BusinessesListing,
  AddressList,
  useConfigFile
} from '~ui'

const inputNames = [
  { name: 'address', code: 'Address' },
  { name: 'internal_number', code: 'Internal number' },
  { name: 'zipcode', code: 'Zipcode' },
  { name: 'address_notes', code: 'Address notes' }
]

const AdvancedAddressFormUI = (props) => {
  const {
    addressesList,
    googleMapsControls,
    formState,
    addressState,
    isRequiredField,
    showField,
    updateChanges,
    handleChangeInput,
    saveAddress,
    setIsEdit,
    userCustomerSetup,
    getNearestBusiness,
    businessNearestState,
    onBusinessClick,
    openBusinessList,
    setOpenBusinessList
  } = props

  const [configState] = useConfig()
  const [orderState] = useOrder()
  const [, t] = useLanguage()
  const formMethods = useForm()
  const [{ auth, user }] = useSession()
  const [, { showToast }] = useToast()
  const theme = useTheme()
  const [, { onChangeBusinessSelected }] = useBusinessSelected()
  const [state, setState] = useState({ selectedFromAutocomplete: true })
  const [alertState, setAlertState] = useState({ open: false, content: [] })
  const [addressTag, setAddressTag] = useState(addressState?.address?.tag)
  const [addingNewAddress, setAddingNewAddress] = useState(true)
  const [addressValue, setAddressValue] = useState(formState.changes?.address ?? addressState.address?.address ?? '')
  const googleInputRef = useRef()
  const [firstLocationNoEdit, setFirstLocationNoEdit] = useState({ value: null })
  const isEditing = !!addressState.address?.id
  const [isCheckGPSLocation, setIsCheckGPSLocation] = useState(false)
  const [locationChange, setLocationChange] = useState(
    addressState?.address?.location || formState?.changes?.location
  )

  const isHideMap = theme?.address?.components?.map?.hidden

  const maxLimitLocation = configState?.configs?.meters_to_change_address?.value
  const googleMapsApiKey = configState?.configs?.google_maps_api_key?.value
  const isLocationRequired = configState.configs?.google_autocomplete_selection_required?.value === '1' ||
    configState.configs?.google_autocomplete_selection_required?.value === 'true'

  const mapErrors = {
    ERROR_NOT_FOUND_ADDRESS: 'Sorry, we couldn\'t find an address',
    ERROR_MAX_LIMIT_LOCATION: `Sorry, You can only set the position to ${maxLimitLocation}m`
  }

  const handleAddressTag = (tag) => {
    setAddressTag(tag)
    handleChangeInput({
      target: {
        name: 'tag',
        value: tag
      }
    })
  }

  const closeAlert = () => {
    setAlertState({
      open: false,
      content: []
    })
  }

  /**
   * Returns true when the user made no changes
   * @param {object} address
   */
  const checkAddress = (address, addressToCompare) => {
    const props = ['address', 'address_notes', 'zipcode', 'location', 'internal_number']
    const values = []
    props.forEach(prop => {
      if (addressToCompare[prop]) {
        if (prop === 'location') {
          values.push(address[prop]?.lat === addressToCompare[prop]?.lat &&
            address[prop]?.lng === addressToCompare[prop]?.lng)
        } else {
          values.push(address[prop] === addressToCompare[prop])
        }
      } else {
        values.push(!address[prop])
      }
    })
    return values.every(value => value)
  }

  const getAddressFormatted = (addressChange) => {
    const data = { address: null, error: null }
    const geocoder = window.google && new window.google.maps.Geocoder()
    if (geocoder) {
      geocoder.geocode({ address: addressChange }, (results, status) => {
        let postalCode = null
        if (status === 'OK' && results && results.length > 0) {
          for (const component of results?.[0].address_components) {
            const addressType = component.types?.[0]
            if (addressType === 'postal_code') {
              postalCode = component.short_name
              break
            }
          }
          data.address = {
            address: addressChange,
            location: { lat: results?.[0].geometry.location.lat(), lng: results?.[0].geometry.location.lng() },
            utc_offset: results?.[0].utc_offset_minutes ?? 0,
            map_data: {
              library: 'google',
              place_id: results?.[0].place_id
            }
          }
          if (postalCode) {
            data.address.zipcode = postalCode
          }
          const arrayList = isEditing
            ? addressesList.filter(address => address.id !== addressState.address?.id) || []
            : addressesList || []
          const addressToCompare = isEditing
            ? { ...addressState.address, ...data.address, ...formState?.changes }
            : { ...data.address, ...formState?.changes }

          const isAddressAlreadyExist = arrayList.map(address => checkAddress(address, addressToCompare)).some(value => value) ?? false
          if (!isAddressAlreadyExist) {
            saveAddress(data.address, userCustomerSetup)
            return
          }

          setAlertState({
            open: true,
            content: [t('ADDRESS_ALREADY_EXIST', 'The address already exists')]
          })
        } else {
          setAlertState({
            open: true,
            content: [t('ERROR_NOT_FOUND_ADDRESS', 'Error, address not found')]
          })
        }
      })
    } else {
      setAlertState({
        open: true,
        content: [t('ERROR_FAILED_LOAD_GEOCODER', 'Failed to load geocoder, please try again.')]
      })
    }
  }

  const checkKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which
    if (keyCode === 13) { e.preventDefault() }
  }

  const onSubmit = async () => {
    if (!auth && formState?.changes?.address === '' && addressState?.address?.address) {
      setAlertState({
        open: true,
        content: [t('VALIDATION_ERROR_ADDRESS_REQUIRED', 'The field Address is required')]
      })
      setLocationChange(null)
      return
    }

    if (formState?.changes?.address && !formState?.changes?.location) {
      if (isLocationRequired) {
        setAlertState({
          open: true,
          content: [t('ADDRESS_REQUIRE_LOCATION', 'The address needs a location, please select one of the suggested')]
        })
        return
      }
      getAddressFormatted(formState?.changes?.address)
      return
    }

    const arrayList = isEditing
      ? addressesList?.filter(address => address?.id !== addressState?.address?.id) || []
      : addressesList || []
    const addressToCompare = isEditing
      ? { ...addressState.address, ...formState.changes }
      : formState?.changes

    const isAddressAlreadyExist = arrayList.map(address => checkAddress(address, addressToCompare)).some(value => value) ?? false

    if (!isAddressAlreadyExist) {
      await saveAddress({}, null)
      await handleRedirectContinue()
      return
    }
    setAlertState({
      open: true,
      content: [t('ADDRESS_ALREADY_EXIST', 'The address already exists')]
    })
  }

  const handleChangeAddress = (address) => {
    setState({
      ...state,
      selectedFromAutocomplete: true
    })
    updateChanges({
      ...address,
      address: googleInputRef?.current?.value || address?.address
    })
  }

  const setMapErrors = (errKey) => {
    setAlertState({
      open: true,
      content: !(errKey === 'ERROR_MAX_LIMIT_LOCATION')
        ? [t(errKey?.toUpperCase().replace(/\s/g, '_'), mapErrors[errKey] ?? errKey)]
        : `${[t(errKey, mapErrors[errKey])]} ${maxLimitLocation} ${[t('METTERS', 'meters')]}`
    })
  }

  const showFieldWithTheme = (name) => {
    return !theme?.address?.components?.[name]?.hidden
  }

  const handleRedirectContinue = async (nearestBusiness) => {
    if (!businessNearestState?.business) {
      showToast(ToastType.Error, t('NO_BUSINESS_NEAR_LOCATION', 'No business near of you location'))
      return
    }
    await onChangeBusinessSelected((nearestBusiness ?? businessNearestState?.business) || null)
    orderState?.options?.type === 1
      ? await onBusinessClick((nearestBusiness ?? businessNearestState?.business))
      : setOpenBusinessList(true)
  }

  useEffect(() => {
    if (!auth) {
      setLocationChange(formState?.changes?.location ?? orderState?.options?.address?.location ?? '')
      setAddressValue(formState?.changes?.address ?? orderState?.options?.address?.address ?? '')
      inputNames.forEach(field =>
        formMethods.setValue(
          field.name,
          formState?.changes[field.name] ||
          (orderState?.options?.address && orderState?.options?.address[field.name]) ||
          ''
        )
      )
      return
    }

    if (!formState.loading && formState.result?.error) {
      setAlertState({
        open: true,
        content: formState.result?.result || [t('ERROR', 'Error')]
      })
    }

    setAddressValue(formState?.changes?.address ?? addressState.address?.address ?? '')
    formMethods.setValue('address', formState?.changes?.address ?? addressState.address?.address ?? '')
    if (!isEditing) {
      inputNames.forEach(field => formMethods.setValue(field.name, formState?.changes[field.name] || ''))
      formState?.changes?.address && setLocationChange(formState?.changes?.location)
      if (
        formState?.changes?.address &&
        formState?.changes?.address !== firstLocationNoEdit?.address &&
        formState?.changes?.location &&
        formState?.changes?.location?.lat !== firstLocationNoEdit.value?.lat &&
        formState?.changes?.location?.lng !== firstLocationNoEdit.value?.lng
      ) {
        setFirstLocationNoEdit({
          value: formState?.changes?.location,
          address: formState?.changes?.address
        })
      }
    }

    if (isEditing) {
      if (formState?.changes?.location) {
        const prevLocation = {
          lat: locationChange?.lat?.toFixed(5),
          lng: locationChange?.lng?.toFixed(5)
        }
        const newLocation = {
          lat: formState?.changes?.location?.lat?.toFixed(5),
          lng: formState?.changes?.location?.lng?.toFixed(5)
        }
        if (
          prevLocation?.lat !== newLocation?.lat &&
          prevLocation?.lng !== newLocation?.lng
        ) {
          setLocationChange(formState?.changes?.location)
        }
      }
    }
  }, [formState])

  useEffect(() => {
    if (isEditing) {
      setIsEdit && setIsEdit(true)
      setAddressValue(addressState.address?.address)
    } else {
      setIsEdit && setIsEdit(false)
    }
  }, [addressState])

  useEffect(() => {
    if (!auth) {
      setLocationChange(formState?.changes?.location ?? orderState?.options?.address?.location ?? '')
    }

    return () => {
      setFirstLocationNoEdit({ value: null })
    }
  }, [])

  /** Form events control */

  useEffect(() => {
    if (Object.keys(formMethods.errors).length > 0) {
      setAlertState({
        open: true,
        content: Object.values(formMethods.errors).map(error => error.message)
      })
    }
  }, [formMethods.errors])

  useEffect(() => {
    inputNames.forEach(field => {
      formMethods.register(field.name, {
        required: isRequiredField(field.name) && showField(field.name)
          ? t(`VALIDATION_ERROR_${field.name}_REQUIRED`, `The field ${field.code} is required`)
          : null
      })
      formMethods.setValue(field.name, formState.changes?.[field.name] ?? addressState.address?.[field.name] ?? '')
    })
  }, [formMethods])

  useEffect(() => {
    const location = {
      lat: formState.changes?.location?.lat || orderState?.options?.address?.location?.lat,
      lng: formState.changes?.location?.lng || orderState?.options?.address?.location?.lng
    }
    if (location?.lat && location?.lng) {
      getNearestBusiness(location)
    }
  }, [
    JSON.stringify(formState.changes?.location),
    orderState?.options?.moment,
    orderState?.options?.type
  ])

  useEffect(() => {
    if (!orderState?.options?.address_id || !businessNearestState?.business || orderState?.options?.type !== 1) return
    getNearestBusiness(orderState?.options?.address?.location, handleRedirectContinue)
  }, [orderState?.options?.address_id])

  useEffect(() => {
    if (configState.loading || addressState.loading || orderState.loading || addressState?.address?.location || orderState?.options?.type !== 2) return
    setIsCheckGPSLocation(true)
  }, [configState.loading, addressState.loading, orderState.loading])

  useEffect(() => {
    if (configState.loading || addressState.loading || orderState.loading || addressState?.address?.location || !isCheckGPSLocation || !formState?.changes?.address || businessNearestState.loading || !businessNearestState?.business) return
    onSubmit()
    setIsCheckGPSLocation(false)
  }, [isCheckGPSLocation, businessNearestState?.loading, configState.loading, addressState.loading, orderState.loading])

  return (
    <Container className='address-form' openBusinessList={openBusinessList}>
      {!openBusinessList
        ? (
          <AddressWrapper
            openBusinessList={openBusinessList}
            hasFormAddress={formState?.changes?.location}
            hasSavedAddress={addressState?.address?.location}
            addingNewAddress={addingNewAddress}
          >
            {(configState.loading || addressState.loading) && (
              <WrapperSkeleton>
                <Skeleton height={50} count={5} style={{ marginBottom: '10px' }} />
              </WrapperSkeleton>
            )}
            <AddressList
              isModal
              isHideTitle
              avoidLoading
              addressList={user?.addresses}
              setAddingNewAddress={setAddingNewAddress}
            />
            <>
              {!configState.loading && !addressState.loading && !addressState?.address?.location && (
                <FormControl
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                  onKeyDown={(e) => checkKeyDown(e)}
                  autoComplete='off'
                >
                  {inputNames.map(field => showField && showField(field.name) && (
                    field.name === 'address'
                      ? (
                        <React.Fragment key={field.name}>
                          <AddressWrap className='google-control'>
                            <WrapAddressInput>
                              <Search className='search-icon'/>
                              <GoogleAutocompleteInput
                                className='input-autocomplete'
                                apiKey={googleMapsApiKey}
                                placeholder={t('ADDRESS', 'Address')}
                                onChangeAddress={(e) => {
                                  formMethods.setValue('address', e.address)
                                  handleChangeAddress(e)
                                }}
                                onChange={(e) => {
                                  handleChangeInput({ target: { name: 'address', value: e.target.value } })
                                  setAddressValue(e.target.value)
                                }}
                                childRef={(ref) => {
                                  googleInputRef.current = ref
                                }}
                                defaultValue={
                                  formState?.result?.result
                                    ? formState?.result?.result?.address
                                    : formState?.changes?.address ?? addressValue
                                }
                                autoComplete='new-password'
                                countryCode={configState?.configs?.country_autocomplete?.value || '*'}
                              />
                            </WrapAddressInput>
                            <GoogleGpsButton
                              className='gps-button'
                              apiKey={googleMapsApiKey}
                              onAddress={(e) => {
                                formMethods.setValue('address', e.address)
                                handleChangeAddress(e)
                              }}
                              onError={setMapErrors}
                              IconButton={GeoAlt}
                              IconLoadingButton={CgSearchLoading}
                              isGetAddress={isCheckGPSLocation}
                            />
                          </AddressWrap>

                          {!isHideMap && locationChange && (addressState?.address?.location || formState?.changes?.location) && (
                            <WrapperMap >
                              <GoogleMapsMap
                                apiKey={googleMapsApiKey}
                                location={locationChange}
                                fixedLocation={!isEditing ? firstLocationNoEdit.value : null}
                                mapControls={googleMapsControls}
                                handleChangeAddressMap={handleChangeAddress}
                                setErrors={setMapErrors}
                                maxLimitLocation={parseInt(maxLimitLocation, 10)}
                              />
                            </WrapperMap>
                          )}
                        </React.Fragment>
                        )
                      : (addressState?.address?.location || formState?.changes?.location) && (
                        <React.Fragment key={field.name}>
                          {(isRequiredField(field.name) || showFieldWithTheme(field.name)) && (
                            <>
                              {field.name !== 'address_notes'
                                ? (
                                  <Input
                                    className={field.name}
                                    placeholder={t(field.name.toUpperCase(), field.code)}
                                    value={formState.changes?.[field.name] ?? addressState.address?.[field.name] ?? ''}
                                    onChange={(e) => {
                                      formMethods.setValue(field.name, e.target.value)
                                      handleChangeInput({ target: { name: field.name, value: e.target.value } })
                                    }}
                                    autoComplete='new-field'
                                    maxLength={30}
                                  />
                                  )
                                : (
                                  <TextArea
                                    rows={3}
                                    placeholder={t('ADDRESS_NOTES', 'Address Notes')}
                                    value={formState.changes?.address_notes ?? addressState.address.address_notes ?? ''}
                                    onChange={(e) => {
                                      formMethods.setValue('address_notes', e.target.value)
                                      handleChangeInput({ target: { name: 'address_notes', value: e.target.value } })
                                    }}
                                    autoComplete='new-field'
                                    maxLength={250}
                                  />
                                  )}
                            </>
                          )}
                        </React.Fragment>
                        )
                  ))}
                  {(addressState?.address?.location || formState?.changes?.location) && (
                    <AddressTagSection>
                      <Button className={addressTag === 'home' ? 'active' : ''} bgtransparent type='button' onClick={() => handleAddressTag('home')}>
                        <span><House /></span>
                      </Button>
                      <Button className={addressTag === 'office' ? 'active' : ''} bgtransparent type='button' onClick={() => handleAddressTag('office')}>
                        <span><Buildings /></span>
                      </Button>
                      <Button className={addressTag === 'favorite' ? 'active' : ''} bgtransparent type='button' onClick={() => handleAddressTag('favorite')}>
                        <span><Heart /></span>
                      </Button>
                      <Button className={addressTag === 'other' ? 'active' : ''} bgtransparent type='button' onClick={() => handleAddressTag('other')}>
                        <span><PlusLg /></span>
                      </Button>
                    </AddressTagSection>
                  )}
                  {formState?.changes?.location && (
                    <FormActions>
                      <Button
                        id='submit-btn'
                        type='submit'
                        disabled={formState.loading}
                        color='primary'
                      >
                        {!formState.loading
                          ? (
                              t('CONTINUE', 'CONTINUE')
                            )
                          : (
                              t('LOADING', 'Loading')
                            )}
                      </Button>
                    </FormActions>
                  )}
                </FormControl>
              )}
            </>
            {!configState.loading && !orderState.loading && !formState.loading && !addressState.loading && addressState?.address?.location && !addingNewAddress && (
              <ButtonWrapper>
                <Button
                  onClick={() => handleRedirectContinue()}
                  disabled={formState.loading || !(addressState?.address?.location || formState?.changes?.location)}
                  color='primary'
                >
                  {!formState.loading
                    ? (
                        t('CONTINUE', 'Continue')
                      )
                    : (
                        t('LOADING', 'Loading')
                      )}
                </Button>
              </ButtonWrapper>
            )}
            <Alert
              title={t('ADDRESS', 'Address')}
              content={alertState.content}
              acceptText={t('ACCEPT', 'Accept')}
              open={alertState.open}
              onClose={() => closeAlert()}
              onAccept={() => closeAlert()}
              closeOnBackdrop={false}
            />
          </AddressWrapper>
          )
        : (
            <BusinessesListing
              {...props}
              showSearchBar
            />
          )}

    </Container>
  )
}

export const AdvancedAddressForm = (props) => {
  const [configFile] = useConfigFile()
  const googleMapsControls = {
    defaultZoom: 17,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeId: 'roadmap', // 'roadmap', 'satellite', 'hybrid', 'terrain'
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite']
    },
    isMarkerDraggable: true
  }
  const addressFormProps = {
    ...props,
    UIComponent: AdvancedAddressFormUI,
    googleMapsControls,
    isSelectedAfterAdd: true,
    franchiseId: configFile?.franchiseSlug
  }

  return <AddressFormController {...addressFormProps} />
}
