import React from 'react'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 0 20px;
`

export const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: auto;
  position: relative;
  ${({ orderCompleted }) => orderCompleted && css`
    max-width: 500px;
    > div {
      padding: 0px 20px 0;
    }
    .order-info {
      margin-bottom: 20px;
    }
  `}
  @media (min-width: 993px) {
    min-height: calc(100vh - 337px);
    box-sizing: border-box;
  }
`

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const HeaderInfo = styled.div`
  width: 80%;
  margin-bottom: 20px;

  h1, p {
    margin: 0;
    color: ${props => props.theme.colors.darkTextColor};
  }

  h1 {
    margin-bottom: 22px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
`

export const OrderBusiness = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px 0px #0000001F;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

export const BusinessWrapper = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${props => props.borderBottom && css`
    padding-bottom: 15px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  `}
  ${props => props.borderTop && css`
    margin-top: 15px;
    border-top: 1px solid ${props => props.theme.colors.lightGray};
  `}
  img {
    width: 100px;
    height: 100px;
    border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
    margin-right: 0;

    @media (min-width: 400px) {
      margin-right: 25px;
    }
  }

  @media (min-width: 400px) {
    width: ${props => props.w ?? '100%'};
  }
`

export const BtsOrderStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div + div {
    margin-left: 0;
    margin-top: 10px;
  }
  @media (min-width: 400px) {
    flex-direction: row;
    div + div {
      margin-left: 30px;
      margin-top: 0px;
    }
  }
`

export const BusinessInfo = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-right: 0;
  margin-top: 10px;

  h1,
  p {
    margin: 0;
    color: ${props => props.theme.colors.darkTextColor};
    overflow-wrap: anywhere;
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    margin: 0px 0px 5px 0px;
  }
  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
`

export const ActionsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > * {
    margin-right: 5px;
    ${props => props.theme?.rtl && css`
      margin-left: 5px;
      margin-right: 0px;
  `}
  }
  svg {
    font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
    cursor: pointer;
    color: #B1BCCC;
  }

  @media (min-width: 380px) {
    svg {
      font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
    }
  }
`

export const OrderInfo = styled.div`
  margin: 0px;
  position: relative;
  background-color: ${props => props.theme.colors.grayDividerColor};
  padding: 30px 20px 0;
  h1, p {
    margin: 0px;
    color: ${props => props.theme.colors.darkTextColor};
  }
  h1 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 32px;
  }
  p {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .order-progress {
    margin: 40px 0 0px;
    padding: 10px 0 20px;
  }
  .date {
    margin-bottom: 6px;
  }

  .types {
    margin-bottom: 6px;
    font-size: 16px;
  }

  .order-status {
    font-weight: 600;
    font-size: 16px;
  }

  #error-subs {
    margin: 20px 0 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  @media (min-width: 768px) {
    padding: 30px 120px 0px;
    #error-subs {
      margin: 20px 0;
    }
  }
`

export const ValidationText = styled.div`
  color: ${props => props.theme.colors.danger500};
  ${props => props.onlyText
? css`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  `
: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.danger500};
    background-color: ${props => props.theme.colors.danger100};

    ${props => props.mb && css`
      margin-bottom: ${props.mb};
    `}

    ${props => props.color && css`
      svg {
        font-size: 21px;
      }
    `}

    svg {
      width: 5%;
    }

    span {
      margin: 0 0 0 5px;
      padding: 0;
      font-size: 14px;
      color: ${props => props.theme.colors.headingColor};
    }
  `}
`

export const OrderIdSec = styled.h1`
  font-size: 25px !important;
  @media (min-width: 350px) {
    font-size: 32px !important;
  }
`
export const ReviewOrderLink = styled.span`
  margin: 0;
  cursor: pointer;
  text-decoration-line: underline;
  user-select: none;
  ${({ active }) => active
? css`
    color: ${props => props.theme?.colors.primary};
  `
: css`
    color: #B1BCCC;
    cursor: not-allowed;
    span {
      pointer-events: none;
    }
  `}
  ${({ isMargin }) => isMargin && css`
    margin: 0 10px 0 0;
  `}
`

const StatusBarStyled = styled.div`
  height: 12px;
  margin: 18px 0px 8px;
  background: ${props => `linear-gradient(to right, ${props.theme.colors.primary} ${props.percentage}%, ${props.theme.colors.disabled} ${props.percentage}%)`};
  ${props => props.theme?.rtl && css`
    background: linear-gradient(to left, ${props.theme.colors.primary} ${props.percentage}%, ${props.theme.colors.disabled} ${props.percentage}%);
  `}
`

export const StatusBar = (props) => {
  return (
    <StatusBarStyled {...props}>
      {props.children}
    </StatusBarStyled>
  )
}

export const SectionTitle = styled.h1`
  margin: 20px 0 0 0;
  font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
  font-weight: 500;
`

export const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
    font-weight: 500;
    margin: 0px 0px 5px 0px;
  }
`

export const OrderCustomer = styled(BusinessInfo)`
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
  padding: 20px 0 20px;
  border-width: 0px 0px 10px;
  border-style: solid;
  border-color: ${props => props.theme.colors.grayDividerColor};
`

export const PhotoBlock = styled.img`
  border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
  width: 100px;
  height: 100px;
  margin-right: 25px;
`

export const Map = styled.div`
  margin-bottom: 10px;
  width: 100%;
  height: 350px;
  > div {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }

  img {
    object-fit: cover;
  }
`

export const OrderDriver = styled(OrderCustomer)``

export const WrapperDriver = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  div.photo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 75px;
      max-height: 75px;
    }

    svg {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      ${props => props.theme?.rtl && css`
        margin-left: 10px;
        margin-right: 0;
      `}
    }
  }

  .driver-info {
    p {
      font-size: ${(props) => props.theme?.utils?.tsize?.s}px;
    }
  }

  .driver-name-wrapper {
    display: flex;
    h2 {
      @media (min-width: 400px) {
        margin-right: 25px;
      }
    }
  }

`

export const OrderProducts = styled(OrderCustomer)`
  flex-direction: column;

  ${({ orderCompleted }) => orderCompleted && css`
    border-width: 0px;
    padding: 10px 0 20px;
  `}
`

export const OrderButtons = styled(OrderCustomer)`

 ${({ showDivider }) => !showDivider && css`
    border-width: 0px;
 `}

  button {
    max-width: -webkit-fill-available;
    height: 50px;
    margin-bottom: 15px;

    @media (min-width: 556px) {
      margin: 0 auto 15px;
      width: 456px;
      max-width: none;
    }
  }
`

export const OrderBill = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-top: 10px;

  table {
    width: 100%;
    font-size: 14px;
    td span {
      unicode-bidi: bidi-override;
    }
    tr td:nth-child(2) {
      text-align: right;
      ${props => props.theme?.rtl && css`
        text-align: left;
      `}
    }
  }

  table.total {
    border-top: 1px solid #BFBFBF;
    margin-top: 15px;
    tr {
      font-size: 14px;
      td:nth-child(1) {
        font-weight: 600;
        font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
        padding-top: 10px;
      }
      td:nth-child(2) {
        font-weight: 600;
        font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
        padding-top: 10px;
      }

      ${({ orderCompleted }) => orderCompleted && css`
        td:nth-child(1) {
          font-weight: 400;
          font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
        }
        td:nth-child(2) {
          font-weight: 400;
          font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
        }
      `}
    }

    ${({ orderCompleted }) => orderCompleted && css`
      border-top: 1px solid ${props => props.theme.colors.gray200};
    `}
  }

  table.comments {
    margin-top: 20px;
    align-items: center;
    tr {
      td:nth-child(1) {
        font-weight: bold;
      }
    }
  }

  .driver {
    padding: 10px 0;
  }

  @media (min-width: 678px) {
    table {
      font-size: 16px;
      ${({ orderCompleted }) => orderCompleted && css`
        font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
        color: ${props => props.theme.colors.black};
      `}
    }
  }
`

export const SkeletonBlock = styled.div`
  width: ${({ width }) => width && `${width}%`};
  border-radius: 16px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SkeletonBlockWrapp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  line-height: 40px;
`

export const ShareOrder = styled.div`
  display: flex;
  margin: 0 0 20px;
  justify-content: space-between;
  z-index: 1;

  div.wrap {
    & > div:first-child {
      left: 0;
    }
    button {
      border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
    }
  }

  h1 {
    font-size: 15px;
    margin: 0px;
  }

  p {
    font-size: 13px;
    margin: 0px;
  }

  button {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 14px;
    align-items: center;
    background: ${props => props.theme.colors.backgroundPage};

    svg {
      left: 0;
      margin-right: 6px;
      color: ${props => props.theme.colors.primary};
    }

    &:hover {
      svg {
        color: #FFFFFF;
      }
    }
  }

  div.text {
    width: 60%;
  }

  div.wrap {
    display: flex;
    align-items: center;

    & > div:first-child {
      width: 100%;
      position: relative;
      div {
        right: 0px;
        left: initial;
        top: 35px;
      }
    }
  }

  @media (min-width: 1201px) {
    div.wrap {
      & > div:first-child {
        left: 0;
      }
    }
  }

  @media (min-width: 768px) {
    div.wrap {
      width: 30%;
      & > div:first-child {
        left: 0;
      }
    }

    h1 {
      font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
    }

    p {
      font-size: 18px;
    }

    button {
      font-size: 18px;
      width: 100%;
      svg {
        position: absolute;
        margin-left: 10px;
      }
    }
  }

  @media (min-width: 425px) {
    div.text {
      width: 70%;
    }

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
    }
  }
`

export const MessagesIcon = styled.span`
  position: relative;
  cursor: pointer;
`

export const ExclamationWrapper = styled.div`
  position: absolute;
  transform: translate(75%, -25%);
  svg{
    font-size: 16px;
    color: ${props => props.theme?.colors?.primary}
  }
`

export const WrapperLeftContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px 0px;

  .map-container {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 768px) {
    padding: 0px 120px 0px;
  }
`

export const WrapperRightContainer = styled(WrapperLeftContainer)`
  ${({ orderCompleted }) => orderCompleted && css`
    .order-progress {
      font-size: ${(props) => props.theme?.utils?.tsize?.s}px;
      font-weight: 800;
    }
  `}
`

export const Divider = styled.div`
  margin: 10px 0;
  height: 1px;
  background: ${props => props.theme.colors.gray200};
  width: 100%;
`

export const MyOrderActions = styled.div``

export const ReviewWrapper = styled.div`
  .review-sent {
    border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
  }
`

export const Exclamation = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  margin-left: 5px;
  top: 3px;
`

export const CommentContainer = styled.div`
  margin: 20px 0;

  h3 {
    font-weight: 800;
    font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
    margin: 0 0 10px;
  }
  span {
    font-size: ${(props) => props.theme?.utils?.tsize?.s}px;
    font-weight: 400;
  }
`
export const NewOrder = styled.div`
  button {
    width: 160px;
  }
`
export const OrderActions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;

  button {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-around;
    min-width: 200px;
    max-height: 53px;
  }
  > h1 {
    text-align: center;
  }

  .delivery-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .date {
      font-size: 18px;
      font-weight: 400;
    }

    .types {
      font-size: ${(props) => props.theme?.utils?.tsize?.m}px;
      font-weight: 800;
    }

    ${({ orderCompleted }) => orderCompleted && css`
      align-items: center;

      .title-container {
        padding: 20px 0px;
        max-width: 250px;
      }

      .date {
        font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
        font-weight: 400;
      }
      .order-status {
        font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
        font-weight: 400;
      }
      .order-status.completed {
        color: ${props => props.theme.colors.success500};
      }
    `}

    @media (min-width: 576px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .time-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${(props) => props.theme?.utils?.tsize?.xxl}px;
    font-weight: 800;
    color: ${(props) => props.theme?.colors?.primary};

    button {
      min-width: 96px;
      width: fit-content;
      max-width: 140px;
      height: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  @media (min-width: 650px) {
    width: 100%;
  }
`

export const ReOrder = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;

  button {
    padding: 5px 16px;
    min-width: unset;
    margin: 5px 5px;

    @media (min-width: 650px) {
      margin: 0px 5px;
    }
  }

  @media (min-width: 650px) {
    flex-direction: row;
  }
`

export const BusinessTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

export const OrderPreferences = styled(OrderCustomer)`
  border-width: 0px;
  padding: 0px;
  margin: 0px;

  .order-type-address {
    font-weight: 800;
    font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
  }

  .order-type {
    display: flex;
    align-items: center;
    height: 45px;
    max-width: 450px;
    border: 1px solid ${props => props.theme.colors.darkTextColor};
    border-radius: ${(props) => props?.theme?.utils?.radius.m}px;
    margin-top: 10px;
    padding: 0px 20px;
    p {
      font-size: ${(props) => props.theme?.utils?.tsize?.s}px;
      font-weight: 700;
      margin: 0;
    }
  }
`

export const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  h1 {
    font-size: ${(props) => props.theme?.utils?.tsize?.xxl}px;
    font-weight: 800;
    margin-bottom: 5px;
  }
`

export const PlaceSpotSection = styled.div`

`
export const OrderStatusAndLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 450px) {
    flex-direction: row;
  }
`
export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const OrderHistoryContainer = styled.div``

export const HistoryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 28px 23px;
  border-bottom: 1px solid ${props => props.theme.colors.gray200};

  > svg {
    width: 24px;
    height: 24px;
    color: ${props => props.theme.colors.primary};
  }
`

export const DetailWrapper = styled.div`
  margin-left: 50px;
  ${props => props.theme.rtl && css`
    margin-left: 0px;
    margin-right: 50px;
  `}

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  button {
    width: 100%;
    height: 44px;
    position: relative;

    svg {
      position: absolute;
      top: 10px;
      font-size: 22px;
      right: 16px;
      ${props => props.theme.rtl && css`
        left: 16px;
        right: initial;
        transform: rotate(180deg);
      `}
    }
  }
`

export const MapWrapper = styled.div`
  width: 100%;
  margin-top: 0px;
`

export const BusinessExternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 769px) {
    width: 50%;
  }
`
export const DirectionButtonWrapper = styled.div`
  margin-top: 30px;

  button {
    height: 50px;
    width: 200px;
  }
`

export const ProfessionalWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px 10px 10px;

  > img {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 8px;
    object-fit: cover;
  }

  > svg {
    width: 55px;
    height: 55px;
    min-width: 55px;
  }

  > p {
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    margin-left: 14px;

    ${props => props.theme.rtl && css`
      margin-left: 0px;
      margin-right: 14px;
    `}
  }

  @media (min-width: 411px) {
    > img {
      width: 65px;
      height: 65px;
      min-width: 65px;
    }
    > svg {
      width: 65px;
      height: 65px;
      min-width: 65px;
    }
  }
`

export const ProfessionalBlock = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.disabled};
  margin-bottom: 15px;
`
export const PlaceSpotWrapper = styled.div`
  @media (min-width: 768px) {
    width: calc(100% - 20px);
  }
`

export const PoweredByOrdering = styled.p`
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  white-space: pre !important;
  margin-bottom: 10px;
  a{
    font-weight: 600;
    margin-left: 5px;
  }
  a:hover{
    text-decoration: none;
  }
`

export const OrderClonnedMessage = styled.div`
  display: flex;
  justify-content: center;
  p {
    font-size: 16px;
    font-weight: 600;
  }
`

export const PaymentWrapper = styled.div`
  margin-top: 10px;

  span.title {
    font-size: ${(props) => props.theme?.utils?.tsize?.xs}px;
    font-weight: 800;
  }

  .payments-list {
    width: 100%;
    margin-top: 10px;
    span {
      font-size: ${(props) => props.theme?.utils?.tsize?.s}px;
    }

    .payment-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .paymethod {
        display: flex;
        flex-direction: column;
      }
    }
  }
`
