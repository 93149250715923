import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import IosRadioButtonOff from '@meronex/icons/ios/IosRadioButtonOff'
import RiRadioButtonFill from '@meronex/icons/ri/RiRadioButtonFill'
import MdClose from '@meronex/icons/md/MdClose'

import {
  Pencil,
  Trash
} from 'react-bootstrap-icons'

import {
  AddressListContainer,
  AddressListUl,
  AddressItem,
  AddressItemActions,
  WrappNotAddresses,
  ContinueButton,
  AddressTitle,
  AddressHalfContainer,
  List,
  AddressFormContainer,
  TitleFormContainer,
  CloseIcon,
  TitleAddress,
  AddButonWrapper
} from './styles'

import {
  AddressList as AddressListController,
  useLanguage,
  useOrder,
  useCustomer,
  useEvent
} from '~components'

import {
  Button,
  Modal,
  Confirm,
  scrollTo,
  useWindowSize,
  NotFoundSource,
  AddressForm,
  capitalize
} from '~ui'

export const AddressListUI = (props) => {
  const {
    actionStatus,
    addressList,
    handleDelete,
    setAddressList,
    handleSetDefault,
    isModal,
    isPopover,
    isProductForm,
    userId,
    userCustomerSetup,
    isEnableContinueButton,
    setCustomerModalOpen,
    isCustomerMode,
    isFromCheckout,
    setIsAddressFormOpen,
    isProfile,
    isMobile,
    onCancel,
    isOpenUserData,
    isHideTitle,
    setAddingNewAddress,
    avoidLoading
  } = props

  const [, t] = useLanguage()
  const [orderState] = useOrder()
  const [events] = useEvent()
  const [curAddress, setCurAddress] = useState(false)
  const [addressOpen, setAddressOpen] = useState(false)
  const [confirm, setConfirm] = useState({ open: false, content: null, handleOnAccept: null })
  const theme = useTheme()
  const [{ user }] = useCustomer()
  const { width } = useWindowSize()
  const isCompletedLayout = width < 769 || isProfile
  const uniqueAddressesList = (addressList.addresses && addressList.addresses.filter(
    (address, i, self) => address.address &&
      i === self.findIndex(obj => (
        address.address === obj.address &&
        address.address_notes === obj.address_notes &&
        address.zipcode === obj.zipcode &&
        address.internal_number === obj.internal_number
      )))) || []

  const showAddress = !theme?.profile?.components?.address_list?.components?.address?.hidden
  const showZipcode = !theme?.profile?.components?.address_list?.components?.zipcode?.hidden
  const showInternalNumber = !theme?.profile?.components?.address_list?.components?.internal_number?.hidden
  const notFoundImage = theme?.profile?.components?.address_list?.components?.image
  const openAddress = (address) => {
    setAddingNewAddress && setAddingNewAddress(true)
    setCurAddress(address)
    setAddressOpen(true)
    setIsAddressFormOpen && setIsAddressFormOpen(true)
    const container = window.document.getElementsByClassName('form_edit')[0]
    container && scrollTo(container, 0, 500)
  }

  const handleSaveAddress = (address) => {
    let found = false
    const addresses = addressList.addresses.map(_address => {
      if (_address?.id === address?.id) {
        Object.assign(_address, address)
        found = true
      } else if (address.default) {
        _address.default = false
      }
      return _address
    })
    if (!found) {
      addresses.push(address)
    }
    setAddressList({
      ...addressList,
      addresses
    })
    if (userCustomerSetup) {
      handleSetAddress(address)
      return
    }
    handleCloseAddressForm()
  }

  const handleSetAddress = async (address) => {
    if (
      checkAddress(address) &&
      isCustomerMode &&
      userCustomerSetup?.id === user?.id &&
      !isFromCheckout
    ) {
      events.emit('go_to_page', { page: 'search' })
      handleSetDefault(address, userCustomerSetup, true)
      setCustomerModalOpen && setCustomerModalOpen(false)
      return
    }

    handleCloseAddressForm()
    await handleSetDefault(address, userCustomerSetup)
    onCancel && onCancel()
  }

  const handleDeleteClick = (address) => {
    setConfirm({
      open: true,
      content: t('QUESTION_DELETE_ADDRESS', 'Are you sure that you want to delete the address?'),
      handleOnAccept: () => {
        handleDelete(address)
        setConfirm({ ...confirm, open: false })
      }
    })
  }

  const checkAddress = (address) => {
    const props = ['address', 'address_notes', 'zipcode', 'location', 'internal_number']
    const values = []
    if (userCustomerSetup) {
      return address.default
    }
    props.forEach(prop => {
      if (address?.[prop]) {
        if (prop === 'location') {
          values.push(address?.[prop].lat === orderState?.options?.address?.[prop]?.lat &&
            address?.[prop].lng === orderState?.options?.address?.[prop]?.lng)
        } else {
          values.push(address?.[prop] === orderState?.options?.address?.[prop])
        }
      } else {
        values.push(orderState?.options?.address?.[prop] === null || orderState?.options?.address?.[prop] === '')
      }
    })
    return values.every(value => value)
  }

  const handleCloseAddressForm = () => {
    setAddressOpen(false)
    setIsAddressFormOpen && setIsAddressFormOpen(false)
  }

  /**
   * Close modals and alerts
   */
  useEffect(() => {
    return () => {
      setConfirm({ ...confirm, open: false })
      handleCloseAddressForm()
    }
  }, [])

  useEffect(() => {
    if (!setAddingNewAddress || addressList?.loading || !addressList?.addresses?.length) return
    setAddingNewAddress(addressOpen)
  }, [addressOpen, addressList?.loading, addressList?.addresses?.length])

  useEffect(() => {
    if (addressList?.addresses?.length === 0 && !addressList.loading && isCustomerMode) {
      openAddress({})
    }
  }, [addressList.loading, addressList?.addresses?.length])

  const AddressListCallcenterLayout = ({ children }) => {
    return (
      <AddressHalfContainer>
        {((!addressOpen && isHideTitle) || !isHideTitle) && (
          <List halfWidth={addressOpen} id='address_list'>
            {children}
          </List>
        )}
        {addressOpen && (
          <AddressFormContainer
            isHideTitle={isHideTitle}
            isCustomerMode={isCustomerMode}
            isOpenUserData={isOpenUserData}
          >
            {!isHideTitle && (
              <TitleFormContainer>
                <CloseIcon>
                  <MdClose onClick={() => handleCloseAddressForm()} />
                </CloseIcon>
                <h1>{t('ADD_NEW_ADDRESS', 'Add new address')}</h1>
              </TitleFormContainer>
            )}
            <AddressForm
              userId={userId}
              addressesList={addressList?.addresses}
              useValidationFileds
              address={curAddress}
              onCancel={() => handleCloseAddressForm()}
              onSaveAddress={handleSaveAddress}
              userCustomerSetup={userCustomerSetup}
            />
          </AddressFormContainer>
        )}
      </AddressHalfContainer>
    )
  }

  const AddressListContent = () => {
    return (
      <>
        {
          (!isPopover || !addressOpen) && !isHideTitle && (
            <>
              {!isCompletedLayout && (
                <TitleAddress>
                  {t('WHAT_IS_YOUR_ADDRESS', 'What\'s your address?')}
                </TitleAddress>
              )}
              <Button
                className='add left-side'
                naked
                color={isEnableContinueButton && addressList?.addresses?.length > 0 ? 'secondary' : 'primaryContrast'}
                onClick={() => openAddress({})}
                disabled={orderState?.loading || actionStatus.loading}
                style={(isCompletedLayout && !isMobile) ? { flex: 1, width: 'fit-content' } : {}}
              >
                {(orderState?.loading || actionStatus.loading) ? t('LOADING', 'Loading') : t('ADD_NEW_ADDRESS', 'Add New Address')}
              </Button>
            </>
          )
        }
        {
          isPopover && addressOpen && (
            <AddressForm
              userId={userId}
              addressesList={addressList?.addresses}
              useValidationFileds
              address={curAddress}
              onCancel={() => handleCloseAddressForm()}
              onSaveAddress={handleSaveAddress}
              userCustomerSetup={userCustomerSetup}
            />
          )
        }

        {
          !addressList.loading &&
          !actionStatus.loading &&
          !orderState.loading &&
          !addressList.error &&
          addressList?.addresses?.length > 0 &&
          typeof orderState.options?.address === 'object' &&
          ((!addressOpen && isPopover) || isModal) && (
            <AddressListUl id='list' isProfile={isProfile}>
              {!isProfile && (
                <AddressTitle>
                  {isHideTitle
                    ? (<h3>{t('MY_SAVED_ADDRESS', 'My saved address')}</h3>)
                    : (t('SELECT_ONE_OF_SAVED_PLACES', 'Select one of your saved places'))}
                </AddressTitle>
              )}
              {uniqueAddressesList.map(address => (
                <AddressItem key={address?.id}>
                  <div className='wrapAddress' onClick={() => !(actionStatus.loading || checkAddress(address)) && handleSetAddress(address)}>
                    <span className='radio'>
                      {checkAddress(address) ? <RiRadioButtonFill className='address-checked' /> : <IosRadioButtonOff />}
                    </span>
                    {(showAddress || showInternalNumber || showZipcode) && (
                      <div className='address'>
                        <span>{t(address?.tag?.toUpperCase(), capitalize(address?.tag))}</span>
                        {
                          showAddress && (
                            <span>{address.address}</span>
                          )
                        }
                        <span>{showInternalNumber && address.internal_number} {showZipcode && address.zipcode}</span>
                      </div>
                    )}
                  </div>
                  <AddressItemActions className='form'>
                    <a className={actionStatus.loading ? 'disabled' : ''} onClick={() => openAddress(address)}>
                      <Pencil />
                    </a>
                    {uniqueAddressesList?.length > 1 && (
                      <a className={(actionStatus.loading || checkAddress(address)) ? 'disabled' : 'red-trash'} onClick={() => handleDeleteClick(address)}>
                        <Trash />
                      </a>
                    )}
                  </AddressItemActions>
                </AddressItem>
              ))}
              {isEnableContinueButton && uniqueAddressesList.map(address => address.default && (
                <ContinueButton key={address.id}>
                  <Button color='primary' onClick={() => handleSetAddress(address)}>
                    {t('CONTINUE_WITH', 'Continue with')}: {address.address}
                  </Button>
                </ContinueButton>
              ))}
            </AddressListUl>
          )
        }

        {!(addressList.loading || actionStatus.loading || orderState.loading) &&
          !addressList.error &&
          addressList?.addresses?.length === 0 &&
          !isProductForm && !isHideTitle &&
          (
            <WrappNotAddresses isCustomerMode={isCustomerMode}>
              {!isCustomerMode && (
                <>
                  <img src={theme.images?.general?.notFound} alt='Not Found' width='200px' height='112px' loading='lazy' />
                  <h1>{t('NOT_FOUND_ADDRESS', 'Sorry, You don\'t seem to have any addresses.')}</h1>
                </>
              )}
            </WrappNotAddresses>
          )}

        {!(addressList.loading || actionStatus.loading || orderState.loading) && addressList.error && (
          addressList.error.length > 0 && (
            <NotFoundSource
              image={notFoundImage}
              content={addressList.error[0]?.message || addressList.error[0]}
            />
          )
        )}

        {!(addressList.loading || actionStatus.loading || orderState.loading) && (typeof orderState.options?.address !== 'object') && !addressList.error && (
          <NotFoundSource
            content={t('NETWORK_ERROR', 'Network error, please reload the page')}
          />
        )}

        {(addressList.loading || actionStatus.loading || orderState.loading) && !isProductForm && !avoidLoading && (
          <AddressListUl>
            <Skeleton height={50} count={3} style={{ marginBottom: '10px' }} />
          </AddressListUl>
        )}
        {isHideTitle && addressList?.addresses?.length > 0 && (
          <AddButonWrapper>
            <Button
              className='add'
              color='primaryContrast'
              naked
              onClick={() => openAddress({})}
              disabled={orderState?.loading || actionStatus.loading}
            >
              {(orderState?.loading || actionStatus.loading) ? t('LOADING', 'Loading') : t('ADD_NEW_ADDRESS', 'Add New Address')}
            </Button>
          </AddButonWrapper>
        )}
      </>
    )
  }

  return (
    <AddressListContainer id='address_control' isLoading={actionStatus?.loading || orderState?.loading} isCompletedLayout={isCompletedLayout}>
      {!isCompletedLayout
        ? (
            <AddressListCallcenterLayout>
              <AddressListContent />
            </AddressListCallcenterLayout>
          )
        : (
            <AddressListContent />
          )}
      {
        !isPopover && addressOpen && isCompletedLayout && (
          <Modal
            title={t('WHAT_IS_YOUR_ADDRESS', 'What\'s your address?')}
            open={!isPopover && addressOpen && isCompletedLayout}
            onClose={() => handleCloseAddressForm()}
          >
            <AddressForm
              userId={userId}
              addressesList={addressList?.addresses}
              useValidationFileds
              address={curAddress}
              onSaveAddress={handleSaveAddress}
              userCustomerSetup={userCustomerSetup}
              onCancel={() => handleCloseAddressForm()}
            />
          </Modal>
        )
      }
      <Confirm
        title={t('SEARCH', 'Search')}
        content={confirm.content}
        acceptText={t('ACCEPT', 'Accept')}
        open={confirm.open}
        onClose={() => setConfirm({ ...confirm, open: false })}
        onCancel={() => setConfirm({ ...confirm, open: false })}
        onAccept={confirm.handleOnAccept}
        closeOnBackdrop={false}
      />
    </AddressListContainer>
  )
}

export const AddressList = React.memo((props) => {
  const addressListProps = {
    ...props,
    UIComponent: AddressListUI
  }

  return <AddressListController {...addressListProps} />
})
