import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow-x: auto;
  margin: 10px;
`
export const UpsellingContainer = styled.div`
  display: flex;

  > div {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
`

export const Item = styled.div`
  display: flex;
  border: 1px solid #eee;
  width: 70%;
  min-width: 150px;
  padding: 20px;
  margin: 15px 0;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  border-radius: 9px;
  box-sizing: border-box;

  @media (min-width: 490px) {
    width: 43%;
  }

  @media (min-width: 769px) {
    width: 27%;
    padding: 11px;
  }

  @media (min-width: 1024px) {
    width: 20%;
    margin: 10px 15px;
  }
`

export const Image = styled.div`
  width: 100%;
  img{
    width: 100%;
    height: auto;
    border-radius: 16px;
    aspect-ratio: attr(width) / attr(height)
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  div {
    width: 100%;
     h3 {
      margin-block-start: 0.1em;
      margin-block-end: 0.1em;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }
  p{
    color: ${props => props.theme.colors.primary};
    margin: 2px auto;
  }
  button {
    width: auto;
    margin: 8px auto 0;
    padding: 0 20px;
    font-size: 1em;
    line-height: 22px;

  }

  @media (min-width: 480px){
    div{
      h3{
        font-size: 14px;
      }
    }
  }

`

export const CloseUpselling = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
  button:first-child{
    margin-bottom: 10px;
  }
  button{
    padding: 10px 0;
  }

  @media (min-width: 720px){
    button{
      width: 40%;
      padding: 2px;
      font-size: 1em;
    }
  }
`

export const SkeletonContainer = styled.div`
  display: flex;
  width: 15%;
  margin: 20px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  border-radius: 9px;
  overflow: hidden;
`

export const HorizontalUpsellingContainer = styled.div`
  display: flex;
  ${({ disableHorizontalScroll }) => disableHorizontalScroll && css`
    flex-wrap: wrap;
    margin: 5px 0px;
  `}
`

export const HorizontalItem = styled.div`
  width: 140px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: ${props => props.theme?.utils?.radius?.m}px;
  margin-right: 35px;
  margin-bottom: 35px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

  button {
    width: 124px;
    height: 33px;
    margin-top: 5px;
    border-radius: ${props => props.theme?.utils?.radius?.m}px;
    font-size: 12px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${props => props.theme.rtl && css`
    margin-left: 15px;
    margin-right: 0px;
  `}
`

export const HorizontalImage = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: ${props => props.theme?.utils?.radius?.m}px;
    min-width: 80px;
    min-height: 80px;
    width: 124px;
    height: 95px;

    ${props => props.theme?.rtl && css`
      margin-right: 10px;
      margin-left: 0;
    `}

  }
`

export const HorizontalDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    line-height: 18px;
  }

  h3 {
    font-weight: 700;
    font-size: 15px;
    color: ${props => props.theme.colors.darkTextColor};
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  span {
    font-size: 12px;
    color: ${props => props.theme.colors.darkTextColor};

    &.discount {
      font-size: 10px;
      color: #909BA9;
      ${props => props.theme?.rtl
? css`
        margin-right: 5px;
      `
: css`
        margin-left: 5px;
      `}
    }
  }


`

export const WrapAutoScroll = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  display: flex;

  ${({ disableHorizontalScroll }) => disableHorizontalScroll && css`
    overflow-x: hidden;
  `}
`
export const UpsellingPageTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.theme?.colors.darkTextColor};
  }

  svg {
    font-size: 20px;
    cursor: pointer;
    color: #748194;
    margin-left: 30px;

    ${props => props.theme?.rtl && css`
      margin-right: 30px;
      margin-left: 0px;
    `}
  }
`

export const Divider = styled.div`
  background: #E9ECEF;
  height: 1px;

`

export const EmptyBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  span {
    font-weight: 600;
    font-size: 16px;
    color: ${props => props.theme.colors.darkTextColor};
  }

  button {
    color: #748194;
    background: #F8F9FA;
    border: 1px solid #F8F9FA;
    border-radius: 7.6px;
    height: 44px;
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
  }
`

export const EmptyCart = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      font-size: 45px;
      color: #B1BCCC;
      margin-top: 10px;
    }

    p {
      font-size: 14px;
      color: #B1BCCC;
      margin: 23px 0 0 0;
    }
  }
`

export const SubtitleContainer = styled.div`
  background: #E9ECEF;
  padding: 15px 10px;
  p {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
  }
`

export const OrderBillContainer = styled.div`
  margin: 10px 20px;
`

export const OrderDetailsTitle = styled.div`
  padding: 15px 20px;
  background: ${({ theme }) => theme?.colors?.primary};
  p {
    margin: 0;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
  }
`

export const CheckProductsContainer = styled.div`
  display: flex;
  margin: 10px 20px;
  align-items: center;
  p {
    color: #909BA9;
    font-size: 15px;
    font-weight: 500;
  }
  button {
    font-size: 11px;
    font-weight: 600;
    padding: 0;
    min-width: 50%;
    height: 50px;
  }
`

export const UpsellingTitle = styled.h1`
  margin: 30px 20px 10px 20px;
  font-size: 36px;
  font-weight: 600;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;

  button {
    height: 50px;
    width: 100%;
    max-width: 180px;
  }
`
