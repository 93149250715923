import styled, { css } from 'styled-components'
import { darken } from 'polished'

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 6px;
  height: 32px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  > svg {
    width: 20px;
    height: 20px;
    color: #B1BCCC;
  }

  &:hover {
    background-color: #1507260a;

    > svg {
      color:  #151b26;
    }
  }

  &:active {
    background-color: #1507261a;
  }

  ${({ color }) => color === 'black' && css`
    > svg {
      color: ${props => props.theme.colors.headingColor};
    }
    &:hover {
      background-color: ${props => props.theme.colors.secundary};
    }
    &:active {
      background-color: ${props => props.theme.colors.secundaryDarkContrast};
    }

    ${({ active }) => active && css`
      background-color: ${props => props.theme.colors.secundaryDarkContrast};
    `}
  `}

  ${({ color }) => color === 'primary' && css`
    > svg {
      color: ${props => props.theme.colors.primary};
    }
    &:hover {
      background-color: #1507260a;
      > svg {
        color: ${props => props.theme.colors.primary};
      }
    }
  `}
  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
  `}
`

export const Button = styled.button`
  background: #E9ECEF;
  color: #000000;
  border: 1px solid #E9ECEF;
  border-radius: ${props => props?.theme?.utils?.radius?.m}px;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  &:active {
    background: ${() => darken(0.07, '#CCC')};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  ${({ width }) => width && css`
    width: ${width};
  `}
  ${({ bgtransparent }) => bgtransparent && css`
      background: transparent !important;
      ${({ disableHover }) => !disableHover && css`
        &:hover {
          background: ${darken(0.15, '#CCC')};
          color: ${props => darken(0.15, props?.theme?.colors?.primary || '#CCC')} !important;
        }
      `}
  `}
  ${({ initialIcon }) => initialIcon && css`
    text-align: left;
    ${props => props?.theme?.rtl && css`
      text-align: right;
    `}
    img{
      vertical-align: middle;
    }
    span {
      padding-left: 15%;
      ${props => props?.theme?.rtl && css`
        padding-right: 15%;
        padding-left: 0;
    `}
    }
  `}
  ${({ theme }) => theme?.general?.components?.buttons?.borderRadius && css`
      border-radius: ${theme?.general?.components?.buttons?.borderRadius};
  `}
  ${({ theme }) => Object.values(theme?.general?.components?.buttons?.shadow?.components || {}).some(val => !!val) && css`
    box-shadow:
      ${theme?.general?.components?.buttons?.shadow?.components?.x || '0px'}
      ${theme?.general?.components?.buttons?.shadow?.components?.y || '0px'}
      ${theme?.general?.components?.buttons?.shadow?.components?.blur || '0px'}
      ${theme?.general?.components?.buttons?.shadow?.components?.spread || '0px'}
      ${theme?.general?.components?.buttons?.shadow?.components?.color || '#000'};
  `}
  ${({ outline }) => outline && css`
    background: #FFF;
    color: #CCC;
    border-color: #CCC;
    &:active {
      color: #FFF;
      background: ${darken(0.07, '#CCC')};
    }
    &:hover {
      background: ${darken(0.07, '#CCC')};
      color: #FFF;
    }
  `}
  ${({ circle }) => circle && css`
    background: #CCC;
    color: #FFF;
    border-color: #CCC;
    padding: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    &:active {
      color: #FFF;
      background: ${darken(0.07, '#CCC')};
    }
  `}
  ${({ circle, outline }) => circle && outline && css`
    background: #FFF;
    color: #CCC;
    border-color: #CCC;
    padding: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    &:active {
      color: #FFF;
      background: ${darken(0.07, '#CCC')};
    }
  `}
  ${({ color }) => color === 'primary' && css`
    background: ${props => props?.theme?.colors?.primary};
    color: ${props => props?.theme?.colors?.primaryContrast};
    border-color: ${props => props?.theme?.colors?.primary};
    &:active {
      background: ${props => darken(0.07, props?.theme?.colors?.primary || '#CCC')};
    }
    &:hover {
      background: ${props => darken(0.07, props?.theme?.colors?.primary || '#CCC')};
      color: #FFF;
    }
    ${({ outline }) => outline && css`
      background: ${props => props.theme.colors.white};
      color: ${props => props?.theme?.colors?.primary};
      border-color: ${props => props?.theme?.colors?.primary};
      &:active {
        color: ${props => props?.theme?.colors?.primaryContrast};
        background: ${props => props?.theme?.colors?.primary};
      }
      &:hover {
        background: ${props => darken(0.07, props?.theme?.colors?.primary || '#CCC')};
        color: #FFF;
      }
    `}
    ${({ circle }) => circle && css`
      background: ${props => props?.theme?.colors?.primary};
      color: ${props => props?.theme?.colors?.primaryContrast};
      border-color: ${props => props?.theme?.colors?.primary};
      padding: 0;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      &:active {
        border-color: ${props => darken(0.07, props?.theme?.colors?.primary || '#CCC')};
        background: ${props => darken(0.07, props?.theme?.colors?.primary || '#CCC')};
      }
    `}
    ${({ circle, outline }) => circle && outline && css`
      background: #FFF;
      color: ${props => props?.theme?.colors?.primary};
      border-color: ${props => props?.theme?.colors?.primary};
      padding: 0;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      &:active {
        border-color: ${props => props?.theme?.colors?.primary};
        background: ${props => props?.theme?.colors?.primary};
      }
    `}
  `}
  ${({ color }) => color === 'primaryContrast' && css`
    background: ${props => props?.theme?.colors?.primaryContrast};
    color: ${props => props?.theme?.colors?.primary};
    border-color: ${props => props?.theme?.colors?.primaryContrast};
    &:hover {
      background: ${props => darken(0.04, props?.theme?.colors?.primaryContrast || '#CCC')};
    }
    &:active {
      background: ${props => darken(0.1, props?.theme?.colors?.primaryContrast || '#CCC')};
    }
    ${({ naked }) => naked && css`
      background: transparent;
      border-color: transparent;
      &:active {
        background: transparent;
      }
      &:hover {
        background: transparent;
      }
    `}
  `}
  ${({ color }) => color === 'secundary' && css`
    background: ${props => props?.theme?.colors?.secundary};
    color: ${props => props?.theme?.colors?.secundaryContrast};
    border-color: ${props => props?.theme?.colors?.secundary};
    &:active {
      background: ${props => darken(0.07, props?.theme?.colors?.secundary || '#CCC')};
    }
    ${({ outline }) => outline && css`
      background: #FFF;
      color: ${props => props?.theme?.colors?.secundary};
      border-color: ${props => props?.theme?.colors?.secundary};
      &:active {
        color: ${props => props?.theme?.colors?.secundaryContrast};
        background: ${props => darken(0.05, props?.theme?.colors?.secundary || '#CCC')};
      }
      &:hover {
        background: ${props => darken(0.07, props?.theme?.colors?.secundary || '#CCC')};
        color: #FFF;
      }
    `}
    ${({ circle }) => circle && css`
      background: ${props => props?.theme?.colors?.secundary};
      color: ${props => props?.theme?.colors?.secundaryContrast};
      border-color: ${props => props?.theme?.colors?.secundary};
      padding: 0;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      &:active {
        color: ${props => props?.theme?.colors?.secundaryContrast};
        border-color: ${props => darken(0.07, props?.theme?.colors?.secundary || '#CCC')};
        background: ${props => darken(0.07, props?.theme?.colors?.secundary || '#CCC')};
      }
    `}
    ${({ circle, outline }) => circle && outline && css`
      background: #FFF;
      color: ${props => props?.theme?.colors?.secundary};
      border-color: ${props => props?.theme?.colors?.secundary};
      padding: 0;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      &:active {
        border-color: ${props => props?.theme?.colors?.secundary};
        background: ${props => props?.theme?.colors?.secundary};
        color: ${props => props?.theme?.colors?.secundaryContrast};
      }
    `}
  `}
  ${({ color }) => color === 'gray' && css`
    background: ${props => props?.theme?.colors?.grayDividerColor};
    color: ${props => props?.theme?.colors?.darkGray};
    border-color: ${props => props?.theme?.colors?.grayDividerColor};
    &:active {
      background: ${props => darken(0.05, props?.theme?.colors?.grayDividerColor || '#CCC')};
    }
    &:hover {
      background: ${props => darken(0.07, props?.theme?.colors?.grayDividerColor || '#CCC')};
    }
  `}
  ${({ color }) => color === 'lightGray' && css`
    background: ${props => props?.theme?.colors?.gray200};
    color: ${props => props?.theme?.colors?.secondaryDark};
    border-color: ${props => props?.theme?.colors?.gray200};
    &:active {
      background: ${props => darken(0.05, props?.theme?.colors?.gray200 || '#CCC')};
    }
    &:hover {
      background: ${props => darken(0.07, props?.theme?.colors?.gray200 || '#CCC')};
    }
  `}
  ${({ color }) => color === 'red' && css`
    background: ${props => props?.theme?.colors?.danger500};;
    color: ${props => props?.theme?.colors?.backgroundPage};
    border-color: ${props => props?.theme?.colors?.danger500};
    &:active {
      background: ${props => darken(0.05, props?.theme?.colors?.danger500 || '#CCC')};
    }
    &:hover {
      background: ${props => darken(0.07, props?.theme?.colors?.danger500 || '#CCC')};
    }
    ${({ outline }) => outline && css`
      background: ${props => props?.theme?.colors?.backgroundPage};
      color: ${props => props?.theme?.colors?.danger500};
      border-color: ${props => props?.theme?.colors?.danger500};
      &:active {
        color: ${props => props?.theme?.colors?.backgroundPage};
        background: ${props => props?.theme?.colors?.danger500};
      }
      &:hover {
        background: ${props => darken(0.07, props?.theme?.colors?.danger500 || '#CCC')};
        color: ${props => props?.theme?.colors?.backgroundPage};
      }
    `}
  `}
  ${({ color }) => color === 'black' && css`
    background: ${props => props?.theme?.colors?.black};;
    color: ${props => props?.theme?.colors?.backgroundPage};
    border-color: ${props => props?.theme?.colors?.black};
    &:active {
      background: ${props => darken(0.05, props?.theme?.colors?.black || '#CCC')};
    }
    &:hover {
      background: ${props => darken(0.07, props?.theme?.colors?.black || '#CCC')};
    }
    ${({ outline }) => outline && css`
      background: ${props => props?.theme?.colors?.backgroundPage};
      color: ${props => props?.theme?.colors?.black};
      border-color: ${props => props?.theme?.colors?.black};
      &:active {
        color: ${props => props?.theme?.colors?.backgroundPage};
        background: ${props => props?.theme?.colors?.black};
      }
      &:hover {
        background: ${props => darken(0.07, props?.theme?.colors?.black || '#CCC')};
        color: ${props => props?.theme?.colors?.backgroundPage};
      }
    `}
  `}
`

export default Button
